<template>
  <div>

    <CContainer class="">
        <CRow>

            <CCol sm="12" class='text-center block-header'>
                <h2>contact us.</h2>
                <p class='text-muted'>
                    If you have any questions for us or want to discuss the possibilities 
                    for your hotel, feel free to contact us!
                </p>
            </CCol>

            <CCol sm="6" class='mt-20 text-muted contact-details text-center contact-info'>
                <CIcon size="custom" name="cil-envelope-closed"/>
                <p><a href="mailto:info@ontherolly.nl">info@ontherolly.nl</a></p>
            </CCol>
            <CCol sm="6" class='mt-20 text-muted contact-details text-center contact-info'>
                <CIcon size="custom" name="cil-screen-smartphone"/>
                <p><a href="tel:085 06 06 646">085 06 06 646</a></p>
            </CCol>
        </CRow>
    </CContainer>

  </div>
</template> 

<script>

import ContactAPI from '/app/src/api/contact.js'

export default {
  name: 'ContactBlock',
  data: () => {
    return {
        contactAPI: new ContactAPI(),
        contactForm: {
            name: '',
            email: '',
            message: ''
        },
        message: ''
    }
  },

  methods: {
      submitForm: function() {
          this.message = 'Sending message...'
          this.contactAPI.submit(
              this.contactForm
          ).then(() => {
              console.log('submitted')
              this.message = 'Your message has been sent! We will be in touch with you shortly.'
          })
          .catch((error) => {
              console.log('submission failed')
              this.message = error
          })
      }
  }
}
</script>

<style scoped>

    .contact-info svg {
        width: 50px !important;
        height: 50px !important;
        color: black;
    }
    .contact-info p {
        font-size: 26px !important;

    }

    .contact-info a {
        color: #3b3950;
    }
    .contact-info a:hover {
        color: #3b3950;
        text-decoration: none;
    }
</style>