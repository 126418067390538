import Base from './base.js'

export default class Project extends Base {

	constructor() {
		super();
		this.route = '/api/project'
	}

	all() {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	detail(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}` , method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	create(data, logo, imagesList) {
		console.log('project creation')
		let self = this
		const formData = new FormData();

		// Add images to form data
		formData.append('id', data.id)
		formData.append('name', data.name)
		formData.append('location', data.location)
		formData.append('description', data.description)
		formData.append('logo', logo)

		for(let i = 0; i < imagesList.length; i++) {
			// images.push(imagesList[i])
			formData.append('images[' + i + ']', imagesList[i])
		}

		return new Promise(function(resolve, reject) {
			self.Axios(
				{
					url: self.route,
					data: formData,
					method: 'POST',
					headers: {
                        'Content-Type': 'multipart/form-data'
                    }
				}
			)
			.then((response) => {
				console.log(response.data)
				resolve(response.data)
			})
			.catch((error) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	update(data, logo, imagesList) {
		console.log('update project')
		console.log(data)
		console.log(imagesList)
		let self = this
		const formData = new FormData();

		// Add images to form data
		formData.append('id', data.id)
		formData.append('name', data.name)
		formData.append('location', data.location)
		formData.append('description', data.description)
		formData.append('logo', data.logo)
		formData.append('newLogo', logo)

		for(let i = 0; i < data.deleteImages.length; i++) {
			formData.append('deleteImages[' + i + ']', data.deleteImages[i])
		}

		for(let i = 0; i < imagesList.length; i++) {
			formData.append('images[' + i + ']', imagesList[i])
		}

		formData.append('_method', 'PUT')

		return new Promise(function(resolve, reject) {
			self.Axios(
				{
					url: self.route + `/${data.id}`, 
					data: formData, 
					method: 'POST',
					headers: {
                        'Content-Type': 'multipart/form-data'
                    }
				})
				.then((response) => {
					console.log(response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(self.parseErrorResponses(error.response.data))
				});
		})
	}

	delete(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}` , method: 'DELETE'})
			.then((response) => {
				// Resolve successfully
				resolve()
			})
			.catch((response) => {
				console.log(response.error)
				reject(false)
			});
		})
	}

}