import Base from './base.js'

export default class Booking extends Base {

	constructor() {
		super();
		this.route = '/api/contact'
	}

	submit(data) {
		console.log('submit contact form')
		let self = this

		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, data: data, method: 'POST'})
			.then((response) => {
				console.log(response.data)
				resolve(response.data)
			})
			.catch((error) => {
				console.log(error)
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

}