<template>
  <div>
    <!-- HERO HEADER -->
    <div class="jumbotron jumbotron-fluid">
      <div class="container text-center">
        <h1 class="hero-header">On The Rolly</h1>
        <p class="hero-header-sub">
          extraordinary bikes for extraordinary hotels
        </p>
      </div>
    </div>

    <!-- SECTION 1-->
    <section class="section section-1">
      <CContainer class="">
        <CRow>
          <CCol sm="6">
            <h2 class="section-title">how we roll.</h2>
            <p class="text-muted">
              Out of the idea that every beautiful hotel deserves a pretty
              bicycle, Simon and Luuk have started on the rolly. Why would you
              design and style everything about an hotel and its online
              appearance and then rent out a meaningless tourist bicycle to
              explore the city. Change was obviously needed. Since Luuk has
              always been busy with bicycles from age 13 and with designing next
              to his technical education, this could in fact be changed. Every
              hotel a different identity, every hotel an own designed bicycle!
            </p>
          </CCol>

          <CCol sm="6" class="mt-20 how-we-roll-image">
            <CImg
              src="/img/frontpage/first-block-image.png"
              block
              class="mb-2 rounded-img"
            />
          </CCol>
        </CRow>

        <CRow class="mt-40">
          <CCol sm="12" class="mt-20 text-center">
            <CImg
              src="/img/frontpage/desktop-steps-min.jpg"
              class="mb-2 hide-mobile"
            />
            <CImg
            src="/img/frontpage/mobile-steps-min.jpg"
            class="mb-2 hide-desktop"
          />
          </CCol>
        
        </CRow>
      </CContainer>
    </section>

    <!-- SECTION 2-->
    <section class="section grey-bg">
      <CContainer>
        <CRow>
          <CCol sm="4">
            <h2 class="section-title">our best projects.</h2>
            <p class="text-muted">
              Find out how we've have helped many other hotels.
            </p>
            <CButton
              class="custom-btn outline"
              type="submit"
              @click="$router.push({ path: '/cases' })"
            >
              All Projects
            </CButton>
          </CCol>
          <CCol sm="8">
            <CRow>
              <CCol sm="6" class="box-col-1" v-for="project in projects">
                <CRow>
                  <CCol sm="12">
                    <div class="box text-center">
                      <div class="box-icon">
                        <i class="mdi mdi-collage"></i>
                      </div>
                      <CImg
                        v-if="project.logo"
                        :src="storageURL + project.logo"
                        width="400"
                        block
                        class="mb-2 case-img"
                      />
                      <CImg
                        v-else
                        src="/img/frontpage/image-placeholder.jpg"
                        width="400"
                        block
                        class="mb-2 case-img"
                      />
                      <div class="box-content">
                        <h5 class="">{{ project.name }}</h5>
                        <div class="project-location">
                          <CIcon name="cil-map" />
                          <span> {{ project.location }}</span>
                        </div>
                        <CButton
                          class="custom-btn outline"
                          type="button"
                          @click="
                            $router.push({ path: '/cases/' + project.id })
                          "
                        >
                          Read More
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CContainer>
    </section>

    <!-- SECTION 3 -->
    <section class="section">
      <CContainer class="">
        <CRow>
          <CCol sm="12" class="text-center block-header">
            <h2 class="section-title">our latest news.</h2>
            <p class="text-muted">Read up on our latest activities.</p>
            <CButton
              class="custom-btn outline"
              type="button"
              @click="$router.push({ path: '/articles' })"
            >
              All Articles
            </CButton>
            <br />
          </CCol>

          <CCol sm="4" class="project-block mt-40" v-for="article in articles">
            <CImg
              v-if="article.image"
              :src="storageURL + article.image"
              width="400"
              block
              class="mb-2 article-img"
            />
            <CImg
              v-else
              src="/img/frontpage/image-placeholder.jpg"
              width="400"
              block
              class="mb-2 article-img"
            />

            <h3>{{ article.title }}</h3>
            <p>{{ article.excerpt }}</p>
            <CLink :to="'/articles/' + article.id" class="custom-link">
              Read More <CIcon name="cil-arrow-right" />
            </CLink>
          </CCol>
        </CRow>
      </CContainer>
    </section>

    <!-- SECTION 4 -->
    <section class="section grey-bg">
      <CContainer class="">
        <CRow>
          <CCol sm="6" class="mt-20 who-we-are-image">
            <CImg
              src="/img/frontpage/luuk-simon.png"
              block
              class="mb-2 rounded-img"
            />
          </CCol>

          <CCol sm="6 mt-20">
            <h2 class="section-title">who we are.</h2>
            <p class="text-muted">
              On the rolly is an Amsterdam based design company specialized in rental bicycles for exclusive hotels. The company was started in 2016 by youth friends Simon de Vries and Luuk de Leeuw, whose friendship already exists since highschool.
            </p>
            <p class="text-muted">
              Apart from on the rolly, Simon is an entrepreneur in the health industry and is also active as physio- and manual therapist. During summer, Simon can be found on the water as he often goes kitesurfing or on the road with his bicycle.
            </p>
            <p class="text-muted">
              Luuk works at a successful Dutch bicycle company as product manager, after having its own hipster-hotspot in Amsterdam, called “The Bicycle Cantine”, where bicycles were repaired but you could also have an haircut or a real strong coffee. Luuk also likes to climb mountains on one of his own bicycles.
            </p>
          </CCol>
        </CRow>
      </CContainer>
    </section>

    <!-- SECTION 4 -->
    <section class="section">
      <ContactForm />
    </section>
  </div>
</template>

<script>
import ProjectAPI from "/app/src/api/project.js";
import ArticleAPI from "/app/src/api/article.js";
import ContactForm from "/app/src/views/frontpage/components/ContactForm";

export default {
  name: "Home",
  components: { ContactForm },
  data: () => {
    return {
      projectAPI: new ProjectAPI(),
      articleAPI: new ArticleAPI(),
      projects: [],
      articles: [],
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },

  created: function () {
    console.log(this.$route.path);
    this.projectAPI
      .all()
      .then((projects) => {
        let maxProjects = 2;
        if (projects.length < maxProjects) {
          maxProjects = projects.length;
        }
        for (let i = 0; i < maxProjects; i++) {
          this.projects.push(projects[i]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.articleAPI
      .all()
      .then((articles) => {
        let maxArticles = 3;
        if (articles.length < maxArticles) {
          maxArticles = articles.length;
        }
        for (let i = 0; i < maxArticles; i++) {
          this.articles.push(articles[i]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style scoped>
.jumbotron {
  color: white;
  background-image: url("/img/frontpage/on-the-rolly-background-dark.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  padding-top: 0 !important;
  margin-bottom: 80px !important;
}

.hero-header {
  padding-top: 180px;
  padding-bottom: 20px;
  color: #f5f5f5 !important;
  font-size: 60px;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.hero-header-sub {
  color: #f5f5f5 !important;
  font-size: 24px;
  padding-bottom: 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

.section-title {
  margin-bottom: 15px;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-1 {
  padding-top: 0;
}

.section-1 img {
  margin: 0 auto;
  object-fit: contain;
}

.step-img {
  height: 120px;
  margin-bottom: 40px !important;
}

.box {
  padding: 0 !important;
  border-radius: 10px !important;
  overflow: hidden;
}

.box:hover {
  background-color: white;
}

.box:hover h5,
.box:hover p {
  color: #3a3950;
}

.box-content {
  padding: 20px;
}

.box-col-1 {
  margin-top: 20px;
}

.box-col-1:nth-child(even) {
  margin-top: -15px;
}

.box-col-1:hover .button {
  background-color: white;
}

.project-location {
  margin-bottom: 10px;
}

.section.grey-bg {
  background-color: #fbfafd;
}

.how-we-roll-image {
  padding: 2%;
}

.selling-points h5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.selling-points-icon {
  padding: 20px 22px;
  background-color: black;
  color: white;
  border-radius: 500px;
  display: inline-block;
}

.selling-points-icon.equals {
  font-size: 24px;
  line-height: 12px;
  padding: 22px 20px;
}

.project-block h3 {
  font-weight: 700;
}

.project-block p {
  font-size: 14px;
}

.project-block img {
  border-radius: 6px;
}

.project-block .project-location {
  text-transform: uppercase;
  color: #9e9cc1;
}

.project-block .project-location svg {
  margin-top: -4px;
  margin-right: 5px;
}

.case-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.article-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

::v-deep label {
  color: #3b3950;
  font-size: 16px;
  font-weight: 500;
}

.who-we-are-image {
  padding: 2%;
}

.contact-details {
  margin-top: 20px;
}

.contact-details svg {
  margin-top: -6px;
}

::v-deep .form-control {
  background-color: rgba(0, 0, 0, 0) !important;
}

.rounded-img {
  border-radius: 10px;
}
</style>
