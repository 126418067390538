import Base from './base.js'

export default class Article extends Base {

	constructor() {
		super();
		this.route = '/api/article'
	}

	all() {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	detail(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}` , method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	create(data, image) {
		console.log('article creation')
		let self = this
		const formData = new FormData();

		formData.append('id', data.id)
		formData.append('title', data.title)
		formData.append('excerpt', data.excerpt)
		formData.append('content', data.content)
		formData.append('image', image)

		formData.append('_method', 'POST')

		return new Promise(function(resolve, reject) {
			self.Axios(
				{
					url: self.route,
					data: formData,
					method: 'POST',
					headers: {
                        'Content-Type': 'multipart/form-data'
                    }
				}
			)
			.then((response) => {
				console.log(response.data)
				resolve(response.data)
			})
			.catch((error) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	update(data, newImage) {
		console.log('article update')
		console.log(newImage)
		let self = this
		const formData = new FormData();

		// Add images to form data
		formData.append('id', data.id)
		formData.append('title', data.title)
		formData.append('excerpt', data.excerpt)
		formData.append('content', data.content)
		formData.append('image', data.image)
		formData.append('newImage', newImage)

		formData.append('_method', 'PUT')

		return new Promise(function(resolve, reject) {
			self.Axios(
				{
					url: self.route + `/${data.id}`, 
					data: formData, 
					method: 'POST',
					headers: {
                        'Content-Type': 'multipart/form-data'
                    }
				})
				.then((response) => {
					console.log(response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(self.parseErrorResponses(error.response.data))
				});
		})
	}

	delete(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}` , method: 'DELETE'})
			.then((response) => {
				// Resolve successfully
				resolve()
			})
			.catch((response) => {
				console.log(response.error)
				reject(false)
			});
		})
	}

}